var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.sisterForumList,"fields":_vm.fields,"busy":_vm.isLoadingComponent,"sort-by":"no","show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPageList - 1) * 10 + index + 1)+" ")]}},{key:"cell(attachment)",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"w-100 rounded",staticStyle:{"object-fit":"cover","height":"50px"},attrs:{"src":item.attachment,"alt":""},on:{"error":function($event){$event.target.src = require("@/assets/images/avatars/no-image.png")}}})]}},{key:"cell(counting)",fn:function(ref){
var item = ref.item;
return [_c('ul',{staticClass:"pl-1"},[_c('li',[_vm._v(_vm._s(item.counting.like)+" Likes")]),_c('li',[_vm._v(_vm._s(item.counting.comment)+" Comments")])])]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category ? item.category.name : "-")+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"danger","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Action ")]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":'/sister-talk-forum/' + item.uuid}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"AlertCircleIcon"}}),_vm._v(" Detail ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(item.uuid)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"TrashIcon"}}),_vm._v(" Delete ")],1)],1)]}}])}),(_vm.resultData.itemsPerPage > 0)?_c('div',{staticClass:"mt-3 d-flex justify-content-between align-items-center"},[_c('small',[_vm._v("Showing "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1)+" to "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1 * _vm.resultData.itemCount)+" from "+_vm._s(_vm.resultData.totalItems))]),_c('b-pagination',{staticClass:"justify-content-end",attrs:{"total-rows":_vm.resultData.totalItems,"per-page":_vm.resultData.itemsPerPage,"aria-controls":"my-table"},on:{"change":_vm.getData},model:{value:(_vm.currentPageList),callback:function ($$v) {_vm.currentPageList=$$v},expression:"currentPageList"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }