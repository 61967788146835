<template>
  <div>
    <b-table
      striped
      hover
      :items="sisterForumList"
      :fields="fields"
      :busy="isLoadingComponent"
      sort-by="no"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(attachment)="{ item }">
        <img
          :src="item.attachment"
          @error="
            $event.target.src = require(`@/assets/images/avatars/no-image.png`)
          "
          class="w-100 rounded"
          style="object-fit: cover; height: 50px"
          alt=""
        />
      </template>

      <template v-slot:cell(counting)="{ item }">
        <ul class="pl-1">
          <li>{{ item.counting.like }} Likes</li>
          <li>{{ item.counting.comment }} Comments</li>
        </ul>
      </template>

      <template v-slot:cell(category)="{ item }">
        {{ item.category ? item.category.name : "-" }}
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item :to="'/sister-talk-forum/' + item.uuid">
            <feather-icon icon="AlertCircleIcon" class="mr-75" />
            Detail
          </b-dropdown-item>
          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    sisterForum: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    //   chooseSelected: {
    //     type: Function,
    //   },
    // chooseFavorite: {
    //   type: Function,
    // },
  },
  watch: {
    isLoading(value) {
      console.log(value);
      this.isLoadingComponent = value;
    },
    sisterForum(value) {
      this.sisterForumList = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
  },
  data() {
    return {
      moment,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      sisterForumList: [],
      fields: [
        { key: "no" },
        { key: "title" },
        { key: "category" },
        { key: "attachment" },
        { key: "counting", label: "Activity" },
        { key: "created_at" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.sisterForumList.length;
    },
  },
};
</script>

<style></style>
